import React, { useEffect, useState } from "react"
import Seo from "../components/Seo"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { css } from "@emotion/core"
import { Box, Container } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import Section from "../components/Section"
import { connect } from "react-redux"
import { setUser } from "../state/actions"
import _groupBy from "lodash.groupBy"
import DialogContent from "@material-ui/core/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import LicenseAgreementText from "../components/LicenseAgreementText"
import uuid from "uuid/v4"
import { setAuthToken, setRefreshToken } from "../services/auth"
import { navigate } from "gatsby"

// const CREATE_ORDER_FREE = gql`
//     mutation CreateOrder($input: CreateOrderInput!) {
//         createOrder(input: $input) {
//             order {
//                 orderId
//                 lineItems {
//                     nodes {
//                         product {
//                             name
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `

const Products = ({ customerData, isLoading }) => {
  if (isLoading) return <CircularProgress />
  if (!customerData || !customerData?.customer?.downloadableItems) return null

  const downloadableItems = customerData.customer.downloadableItems.nodes

  // const [
  //   createOrder,
  //   { data: orderData, error: orderError, loading: orderLoading },
  // ] = useMutation(CREATE_ORDER_FREE)

  //
  // if (downloadableItems.length === 0) {
  //   // console.log(customerData)
  //
  //   createOrder({
  //     variables: {
  //       input: {
  //         clientMutationId: uuid(),
  //         lineItems: {
  //
  //         }
  //       },
  //     },
  //   }).then(response => {
  //     // console.log("Create Order Response", response)
  //
  //     const { order } = response.data
  //   })
  // }

  // Group items by the name of the product
  const groupedItems = _groupBy(downloadableItems, "product.name")

  return Object.keys(groupedItems).map((productName, index) => (
    <Product
      key={index}
      productName={productName}
      downloads={groupedItems[productName]}
    />
  ))
}

const useProductsStyles = makeStyles({
  card: {
    marginBottom: 16,
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  contentOne: {
    backgroundColor: "#6111b1",
    padding: 24
  },
  title: {
    marginBottom: 0
  },
  actionsPadding: {
    padding: 16,
  },
  dialog: {
    textAlign: "center",
    margin: 32,
  },
  scrollView: {
    height: "100%",
    maxHeight: 400,
    overflowX: "hidden",
    overflowY: "auto",
    padding: "1em 1em 0 0",
    marginTop: 16,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  topActions: {
    textAlign: "right",
    width: "100%"
  },
})

const Product = ({ productName, downloads }) => {
  const classes = useProductsStyles()

  const [open, setOpen] = useState(false)
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const releaseNoteUrl = downloads?.[0].product?.attributes?.nodes?.[0].options?.[0] ?? "https://docs.mobileui.dev/whats-new/"
  return (
    <Card className={classes.card}>
      <CardContent className={classes.contentOne}>
        <Typography
          color={"secondary"}
          gutterBottom
          variant="h4"
          component="h3"
          className={classes.title}
        >
          {productName}
        </Typography>
      </CardContent>
      <CardContent>
        <div className={classes.topActions}>
          <Button href={"https://docs.mobileui.dev"} target={"_blank"} size="small" color="primary">
            Documentation
          </Button>
          <Button href={releaseNoteUrl} target={"_blank"} size="small" color="primary">
            Release Notes
          </Button>
        </div>

        {downloads.map((downloadItem, index) => (
          <Download
            key={index}
            downloadItem={downloadItem}
            handleClickOpen={handleClickOpen}
          />
        ))}

        <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
          <DialogContent className={classes.dialog}>
            <div>
              <Typography>
                You must accept the License Agreement<br/>for {productName} to download this software.
              </Typography>

              <div className={classes.scrollView}>

                {
                  downloads[0].product?.shortDescription &&
                  <LicenseAgreementText content={downloads[0].product?.shortDescription}/>
                }
              </div>
            </div>
            <Divider className={classes.divider} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsOfUseChecked}
                  required
                  onChange={(e, check) => setTermsOfUseChecked(check)}
                  name="termsOfUseChecked"
                  color="primary"
                />
              }
              label={
                <Typography variant={"body1"}>
                  I reviewed and accept the License Agreement
                </Typography>
              }
            />
            {downloads.map((downloadItem, index) => (
              <DownloadButton
                key={index}
                disabled={!termsOfUseChecked}
                downloadItem={downloadItem}
              />
            ))}
            <p>Safari users: Please use <strong>Right Click → Download linked file</strong> to keep the ZIP file.</p>
          </DialogContent>
        </Dialog>
      </CardContent>
      <CardActions className={classes.actionsPadding}>
        <Button onClick={handleClickOpen} color="primary" size={"large"} variant={"contained"}>
          Download
        </Button>
      </CardActions>
    </Card>
  )
}

const GET_CUSTOMER = gql`
  query GetCustomer($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerId
      billing {
        company
      }
      downloadableItems {
        nodes {
          downloadId
          name
          url
          download {
            file
          }
          product {
            productId
            name
            shortDescription
            image {
              sourceUrl
            }
            attributes {
              nodes {
                options
              }
            }
          }
        }
      }
    }
  }
`



const getFileNameFromUrl = url => url.substring(url.lastIndexOf("/") + 1)

const useStylesDownloads = makeStyles({
  download: {
    marginTop: 16,
    marginBottom: 16,
  },
  fileDownload: {
    cursor: "pointer"

  }
})

const Download = ({ downloadItem, handleClickOpen}) => {
  const classes = useStylesDownloads()

  const { name, download } = downloadItem

  return (
    <div className={classes.download}>
      <Typography variant={"h5"} component={"h4"}>{name}</Typography>
      <Typography
        variant={"body2"}
        color={"primary"}
        className={classes.fileDownload}
        onClick={handleClickOpen}
      >
        {getFileNameFromUrl(download.file)}
      </Typography>
    </div>
  )
}

const useStylesDownloadButtons = makeStyles({
  buttonLink: {
    marginTop: 16,
    padding: "4px 8px",
    textTransform: "none",
    "& .MuiButton-label": {
      fontWeight: 400,
    },
  },
})

const DownloadButton = ({ downloadItem, disabled }) => {
  const classes = useStylesDownloadButtons()

  const { name, url, download } = downloadItem

  const renderedUrl = disabled ? null : url
  return (
    <div>
      <Button
        variant={"contained"}
        color={"primary"}
        disabled={disabled}
        className={classes.buttonLink}
        href={renderedUrl}
        title={name}
      >
        <strong>DOWNLOAD</strong>&nbsp;&nbsp;{getFileNameFromUrl(download.file)}
      </Button>
    </div>
  )
}

const DashboardIndex = ({ user }) => {
  const [
    getCustomerData,
    { data: customerData, loading: customerLoading, error: customerError },
  ] = useLazyQuery(GET_CUSTOMER)

  useEffect(() => {
    if (user.id && !customerData) {
      getCustomerData({ variables: { customerId: user.id } })
    }
  }, [user])

  if (customerLoading)
    return (
      <div
        css={css`
          display: flex;
          height: 100vh;
          justify-content: center;
          align-items: center;
        `}
      >
        <CircularProgress />
      </div>
    )
  if (!user || !user.username) return null

  return (
    <Section textAlign={"left"} largeSpacing>
      <Container maxWidth={"md"}>
        <Seo title="Dashboard" />

        <Typography variant={"h3"}>Your Products</Typography>
        <Divider
          css={css`
            margin: 16px 0;
          `}
        />
        <Box>
          <Products customerData={customerData} isLoading={customerLoading} />
        </Box>

      </Container>
    </Section>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  setUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIndex)
