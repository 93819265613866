import React from "react"
import { Container } from "@material-ui/core"
import Section from "../components/Section"
import Seo from "../components/Seo"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  spacing: {
    marginTop: 32
  }
})

const Account = ({}) => {
  const classes = useStyles()

  return (
    <Section textAlign={"center"} largeSpacing>
      <Container maxWidth={"md"}>
        <Seo title="Dashboard - Account" />

        <Typography variant={"body1"}>
          Since we are still in the process of getting everything up and
          running, we are still missing this sites functionality.
        </Typography>
        <Typography className={classes.spacing} variant={"body2"}>
          If you need anything like deleting or changing your account, please
          write us an e-mail to{" "}
          <a href={"mailto:info@mobileui.dev"}>info@mobileui.dev</a>
        </Typography>
      </Container>
    </Section>
  )
}
export default Account
