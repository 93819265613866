import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/PrivateRoute"
import Account from "../dashboard/Account"
import Layout from "../components/Layout"
import DashboardIndex from "../dashboard"
import PageNotFound from "../dashboard/PageNotFound"
import Seo from "../components/Seo"


const Dashboard = () => {

  return (
    <Layout>
      <Seo title={"Dashboard"} />

      {/* This also defines the space, where the routes get rendered */}
        <Router id="router">
          <PageNotFound default/>
          <PrivateRoute path={"/dashboard/"} component={DashboardIndex}/>
          <PrivateRoute path={"/dashboard/my-account/"} component={Account}/>
        </Router>

    </Layout>
  )
}

export default Dashboard
